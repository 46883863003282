@import '../../../../scss/helpers';

.profileGraphWrapper {
  width: 100%;
  height: 100px;
  margin-bottom: 60px;
  position: relative;
}

.yAxis {
  left: -60px;
  bottom: 50px;
  position: absolute;
  color: $cooperate-text-color;
  @include font-chart-axis;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.graphContainer {
  min-height: 100px;
  height: 105px;
}

.xAxis {
  color: $cooperate-text-color;
  @include font-chart-axis;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
