$menu-text-color: #1957c2;
$cooperate-text-color: #8d9cc5;
$star-cooperate-color: #cbd7eb;

$color-red: #ed1c24;
$color-header: #011769;

$header-height: 80px;
$sub-header-height: 60px;

$box-shadow-menu: 0px 5px 5px -5px rgba(25, 87, 194, 0.15);

$mobile-breakpoint: 320px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1279px;

$border: 1px solid rgba(25, 87, 194, 0.15);

$left-menu-hover: #f7fafd;
