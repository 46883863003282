@import '../../../scss/helpers';

.tableCell {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  text-align: left;
  color: $color-header;

  @include cooperateText;

  &.tableCellTh {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
    font-weight: 600;
  }

  &:first-child {
    padding-left: 0;
  }

  &.mobileDisabled {
    @include mobile {
      display: none;
    }
  }

  &.tabletDisabled {
    @include tablet {
      display: none;
    }
  }
}
