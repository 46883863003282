@import '../scss/base';
@import '../scss/helpers';
@import '../../node_modules/antd/dist/antd.css';

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  font: normal normal 400 13px /20px 'Open sans', sans-serif;
  color: $menu-text-color;

  @include mobile {
    font: normal normal 400 12px /18px 'Open sans', sans-serif;
  }
}

body,
html,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

img,
svg {
  vertical-align: middle;
}

h1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;

  // @include tablet {
  //   line-height: 25px;
  // }

  // @include mobile {
  //   font-size: 15px;
  //   line-height: 20px;
  // }
}

a {
  a:hover {
    text-decoration: underline;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  background-color: transparent;
  color: inherit;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(25, 87, 194, 0.05);
  color: $menu-text-color;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled):hover {
  background-color: rgba(25, 87, 194, 0.05);
  color: $menu-text-color;
}

.ant-menu-root.ant-menu-vertical {
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.ant-dropdown {
  z-index: 999999;
}
