@import '../../../scss/helpers';

.navWrapper {
  .nav {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    .navItem {
      position: relative;

      &.navItemActive {
        .navItemLink {
          background-color: rgba(25, 87, 194, 0.05);
        }

        &::before {
          border: none;
        }

        & + .navItem {
          &::before {
            content: none;
          }
        }
      }

      .navItemLink {
        text-decoration: none;
        color: $menu-text-color;
        padding: 20px;
        border-radius: 5px;

        svg {
          fill: $menu-text-color;
        }

        &.navItemLinkIcon {
          span:first-child {
            margin-right: 11px;
          }
        }

        & > span {
          @include font-menu;
        }
      }

      &::before {
        content: '';
        border-right: 1px solid $menu-text-color;
      }

      &:first-child {
        &::before {
          content: none;
        }
      }
    }

    @include mobile {
      display: none;
      position: fixed;
      left: 0;
      top: $header-height;
      width: 100%;
      background-color: $color-header;
      padding-top: 28px;

      .navItem {
        .navItemLink {
          width: 100%;
          display: block;
          padding: 17px 20px;
          color: #fff;

          & > svg {
            path {
              fill: #fff;
            }
          }
        }

        svg {
          fill: #fff;
        }

        &.navItemActive {
          .navItemLink {
            background-color: rgba(0, 0, 0, 0.15);
          }
        }

        &::before {
          content: none;
        }
      }

      &.openMenu {
        display: block;
      }
    }
  }
}
