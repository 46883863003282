@import '../../../scss/helpers';

.scoreWrapper {
  @include font-description-table;
  margin-left: 10px;
  color: $color-header;

  &.light {
    color: $cooperate-text-color;

    & > span > svg {
      fill: $cooperate-text-color;
    }
  }

  .scoreText {
    margin-left: 10px;
  }
}
