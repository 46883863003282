.listRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-bottom: 14px;
  box-shadow: 0px 2px 5px rgba(25, 87, 194, 0.05);

  border-radius: 5px;

  overflow: hidden;
  border-top: 1px solid rgba(25, 87, 194, 0.05);
  border-right: 1px solid rgba(25, 87, 194, 0.05);
  border-left: 1px solid rgba(25, 87, 194, 0.05);

  &.cursor {
    cursor: pointer;
  }
}
