@import 'variables.scss';

@mixin mobile {
  @media screen and (max-width: #{$tablet-breakpoint - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$tablet-breakpoint}) and (max-width: #{$desktop-breakpoint - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop-breakpoint}) {
    @content;
  }
}

@mixin grid($main: null) {
  margin: 0 auto;

  @include mobile {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  @include tablet {
    @if ($main) {
      width: 728px;
    } @else {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  @include desktop {
    @if ($main) {
      width: 1000px;
    } @else {
      padding-right: 70px;
      padding-left: 70px;
      width: 100%;
    }
  }
}

@mixin font-logo-top {
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
}

@mixin font-logo-bottom {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

@mixin cooperateText {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

@mixin font-menu {
  font-size: 15px;
  line-height: 20px;
}

@mixin font-score-user {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

@mixin font-user-name {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

@mixin font-tags {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

@mixin font-description-table {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

@mixin font-h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
}

@mixin font-chart-axis {
  font-weight: 600;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
