@import '../../../scss/helpers';

.tableCellTitle {
  @include mobile {
    display: flex;
    flex-direction: column;
  }

  a {
    @include font-description-table;
    font-weight: 600;
    color: $menu-text-color;

    &:hover {
      text-decoration: underline;
    }
  }

  .dateTitle,
  .typeTitle {
    display: none;

    @include mobile {
      display: block;
    }
  }

  .dateTitle {
    margin-bottom: 18px;
    @include cooperateText;
    color: $color-header;
  }

  .typeTitle {
    margin-top: 18px;
    @include cooperateText;
    color: $cooperate-text-color;
  }
}
