@import '../../../scss/helpers';
.listCell {
  flex: 1;

  &.mobileDisabled {
    @include mobile {
      display: none;
    }
  }

  &.mobileSmall {
    @include mobile {
      flex: 0.5;
    }
  }
}
