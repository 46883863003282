@import '../../../../scss/helpers';

.tooltipWrapper {
  display: grid;
  gap: 6px;
  background-color: $color-header;
  padding: 10px;
  @include font-chart-axis;
  color: #fff;

  .answers {
    color: #15C0CE;
  }

  .content {
    color: #66B1F5;
  }
}
