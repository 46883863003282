@import '../../../../scss/helpers';

.profileHeader {
  margin-top: 80px;
  margin-bottom: 60px;
  background-color: #fff;
  box-shadow: 0px 0px 5px rgba(25, 87, 194, 0.15);
  border-radius: 5px;
  display: flex;

  @include mobile {
    margin-top: 50px;
  }

  .profile {
    display: flex;
    width: 100%;

    @include mobile {
      margin-bottom: 40px;
    }
  }

  @include mobile {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;

    flex-direction: column;
  }

  .profileContent {
    margin-left: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 0;
    @include mobile {
      padding: 0;
    }
  }

  .profileTopContent,
  .profileBottomContent {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .profileTopContent {
    border-bottom: 1px solid rgba(25, 87, 194, 0.15);

    @include mobile {
      border-bottom: none;
    }

    .profileRank {
      display: flex;
      padding-bottom: 20px;
      padding-top: 10px;
    }

    .profileRankContent {
      display: flex;
      font-weight: 600;
      font-size: 11px;
      line-height: 17px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #1957C2;
      margin-right: 30px;

      svg {
        margin-top: 4px;
        margin-right: 10px;
      }
    }

    .profileRankAnswers {
      display: flex;
      margin-right: 30px;
      font-weight: 600;
      font-size: 11px;
      line-height: 17px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #15C0CE;

      svg {
        margin-top: 4px;
        margin-right: 10px;
      }
    }

    .profileTopContentLeft {
      h3 {
        text-transform: uppercase;
        @include font-h3;
        color: $color-header;
      }

      .profileTopContentLeftRow {
        display: flex;

        & > div:first-child {
          margin-right: 23px;
        }
      }
    }
  }

  .profileBottomContent {
    @include mobile {
      display: none;
    }

    .profileBottomContentRight {
      flex: 0.5;
    }
  }
}

.mobileProfile {
  display: none;

  @include mobile {
    display: block;
    margin-bottom: 40px;
  }
}
