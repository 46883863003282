@import '../../../scss/helpers';

.cooperateText {
  @include cooperateText;
  color: $cooperate-text-color;

  @include mobile {
    display: inline-block;
  }
}
