@import '../../../scss/helpers';

.wrapperSearch {
  width: 100%;
  text-align: right;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include mobile {
    justify-content: flex-start;
  }

  .searchIcon {
    position: relative;
    z-index: 9;
    margin-top: 5px;
    margin-left: -25px;
  }

  .wrapperSearchInput {
    width: 0;
    display: block;
    overflow: hidden;
    -webkit-transition: width 0.6s ease-in-out;
    transition: width 0.6s ease-in-out;

    @include mobile {
      width: 100%;
    }
  }

  &.wrapperSearchOpen {
    .wrapperSearchInput {
      width: 100%;
      -webkit-animation: fadeInFromNone 0.8s ease-out;
      -moz-animation: fadeInFromNone 0.8s ease-out;
      -o-animation: fadeInFromNone 0.8s ease-out;
      animation: fadeInFromNone 0.8s ease-out;
    }
  }
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
