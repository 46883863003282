@import '../../../scss/helpers';

.titleTableWrapper {
  @include grid('content');
  margin-top: $sub-header-height;
  padding-top: 70px;
  display: flex;

  .titleTable,
  .descriptionTable {
    flex: 1;
    color: $color-header;
  }

  .titleTable {
    color: $color-header;
  }

  .descriptionTable {
    @include font-description-table;
  }

  @include mobile {
    padding-top: 40px;
    margin-top: 0;
    flex-direction: column;
  }
}
