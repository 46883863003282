@import '../../../scss/helpers';

.wrapperHeader {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: $header-height;
  width: 100%;
  background: #fff;
  box-shadow: $box-shadow-menu;
  display: flex;
  align-items: center;

  @include mobile {
    z-index: 99999;
  }

  .headerContent {
    @include grid();
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
