@import '../../../../scss/helpers';

.profileContributionsWrapper {
  display: flex;
  margin-bottom: 80px;

  @include mobile {
    flex-direction: column;
  }

  .leftFilters {
    margin-right: 20px;

    & > div:first-child {
      margin-bottom: 10px;
    }
  }
}
