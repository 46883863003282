@import '../../../scss/helpers';

.wrapperLogo {
  display: flex;

  .logoImage {
    margin-right: 20px;
  }

  .logoTopText {
    cursor: pointer;
  }
}

.logoTopTextLink {
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    margin-left: 2px;
  }
}

.logo {
  display: flex;
  flex-direction: column;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  .logoTopText {
    text-transform: uppercase;
    @include font-logo-top;
    color: $color-red;
  }

  .logoBottomText {
    text-transform: uppercase;
    @include font-logo-bottom;
    color: $color-header;
  }
}
