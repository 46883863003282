@import '../../../../scss/helpers';

.menuWrapper {
  flex: 0.3;

  .menu {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(25, 87, 194, 0.15);
    border-radius: 5px;

    @include mobile {
      display: none;
    }

    .menuItem {
      padding: 15px 20px;
      // border-bottom: $border;
      cursor: pointer;
      color: $menu-text-color;
      text-transform: capitalize;

      @include cooperateText;

      &:hover {
        background: $left-menu-hover;
      }

      &.menuItemActive {
        font-weight: 700;
      }
    }
  }
  
  .topBorder {
    border-top: $border;
  }

  .mobileMenu {
    display: none;

    @include mobile {
      display: block;
      margin-bottom: 40px;
    }
  }
}
