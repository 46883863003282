@import '../../../scss/helpers';

.SwitcherWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  width: 100%;
  border: 1px solid rgba(25, 87, 194, 0.15);
  border-radius: 5px;
  box-sizing: border-box;
  // overflow: hidden;

  .switcherItem {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    width: 100%;
    height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    background-color: white;

    .icon {
      padding-right: 10px;
    }
  }

  .selectedItem {
    @extend .switcherItem;
    background-color: rgba(25, 87, 194, 0.05);
  }
}
