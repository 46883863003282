@import '../../../scss/helpers';

.viewContent {
  @include grid('content');
  margin-bottom: 40px;
}

.viewHead {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;

  @include mobile {
    margin-bottom: 30px;

    & > div:first-child {
      order: 2;
    }

    & > div:last-child {
      order: 1;
    }
  }
}

.viewLoadMore {
  text-align: center;
  margin: 0 0 80px 0;
}
