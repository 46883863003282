@import '../../../scss/helpers';

.listTags {
  @include font-tags;
  color: $cooperate-text-color;
  max-height: 45px;
  overflow: hidden;

  &.blue {
    color: $menu-text-color;
  }

  .listTagItem {
    cursor: pointer;
    text-transform: capitalize;

    &:hover {
      text-decoration: underline;
    }
    margin-right: 2px;

    &::after {
      content: ',';
    }

    &:last-child {
      &::after {
        content: '';
      }
    }
  }
}
