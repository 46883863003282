@import '../../../scss/helpers';

.subHeader {
  position: fixed;
  z-index: 999;
  width: 100%;
  left: 0;
  top: $header-height - 1px;
  height: $sub-header-height;
  background-color: #fff;
  box-shadow: $box-shadow-menu;
  border-top: 1px solid rgba(25, 87, 194, 0.1);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

  @include mobile {
    background-color: transparent;
    position: inherit;
    box-shadow: none;
    border-top: none;
    margin-top: 30px;
    height: 100%;
  }
}

.subHeaderContent {
  background-color: #fff;
  padding: 10px 0;
  @include grid();

  @include mobile {
    background-color: transparent;
  }
}
