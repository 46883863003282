@import '../../../scss/helpers';

.antFormLoadMore {
  border: 1px solid $cooperate-text-color;
  font-weight: 400;
  border-radius: 5px;
  background-color: transparent;
  color: $cooperate-text-color;

  &:hover {
    border: 1px solid $menu-text-color;
    color: $menu-text-color;
  }
}
