@import '../../../scss/helpers';

.thead {
  padding: 20px 0;
  background-color: $color-header;

  & > tr {
    border-radius: 4px;
    background-color: $color-header;
    border-bottom: 0;

    & > th:first-child {
      padding: 0 20px;
      border-radius: 4px 0 0 4px;

      @include mobile {
        padding: 15px 10px;
      }
    }

    & > th:last-child {
      padding: 0 20px;
      border-radius: 0 4px 4px 0;

      @include mobile {
        padding: 15px 10px;
      }
    }

    @include mobile {
      padding: 15px 10px;
    }
  }
}
