@import '../../../scss/helpers';

.avatarWrapper {
  width: 90px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  &.small {
    width: 60px;
  }

  &.large {
    width: 200px;
  }

  &.mobileSmall {
    @include mobile {
      width: 87px;
      height: 87px;
      flex-shrink: 0;
    }

    &.avatarLeftRound {
      @include mobile {
        border-radius: 5px;
      }
    }
  }

  &.avatarLeftRound {
    border-radius: 5px 0 0 5px;
  }

  &.round {
    border-radius: 5px;
  }
}

.largeWrapper {
  width: 200px;
  height: 200px;
}

.avatar {
  width: 100%;

  &.small {
    width: 60px;
  }

  &.large {
    width: 200px;
  }

  &.avatarLeftRound {
    border-radius: 5px 0 0 5px;
  }

  &.mobileSmall {
    @include mobile {
      width: 87px;
      height: 87px;
      border-radius: 5px;
    }
  }

  &.round {
    border-radius: 5px;
  }
}
