@import '../../../../scss/helpers';

.profileBottomContentLeft {
  display: flex;
  flex: 1;
  justify-content: space-between;

  @include mobile {
    flex-direction: column;
  }

  .profileBottomContentLeftItem {
    @include mobile {
      display: flex;
      justify-content: space-between;
      margin-bottom: 23px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .profileBottomContentLeftItemTitle {
    @include font-description-table;
    color: $cooperate-text-color;
    @include mobile {
      flex: 0.6;
    }
  }

  .profileBottomContentLeftItemText {
    @include font-logo-top;
    line-height: 20px;
    color: $menu-text-color;

    @include mobile {
      flex: 1;
      text-align: left;
    }

    a {
      @include font-logo-top;
      line-height: 20px;
      color: $menu-text-color;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
