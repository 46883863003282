@import '../../../../scss/helpers';

.wrapperFilter {
  display: flex;
  // justify-content: space-between;
  align-items: center;

  @include mobile {
    flex-direction: column;

    & > div {
      &:last-child {
        margin-bottom: 0;
      }

      margin-bottom: 11px;
    }
  }

  & > div:first-child {
    flex: 0.3;
    margin-right: 10px;

    @include mobile {
      margin-right: 0;
    }
  }

  & > div:nth-child(2) {
    flex: 0.3;
    margin-right: 10px;

    @include tablet {
      max-width: 180px;
    }

    @include desktop {
      max-width: 180px;
    }

    @include mobile {
      margin-right: 0;
    }
  }

  & > div:last-child {
    flex: 1;
    // min-width: 300px;

    @include tablet {
      margin-right: 20px;
    }

    @include desktop {
      margin-right: 20px;
    }
  }
}
