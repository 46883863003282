@import '../../../../scss/helpers';

.aboutWrapper {
  margin-top: 100px;

  a {
    text-decoration: underline;
  }

  h1 {
    @include font-h3;
    color: $color-header;
    margin-bottom: 50px;
    margin-top: 0;
  }

  h3 {
    @include font-user-name;
    color: $color-header;
    margin-bottom: 24px;
  }

  p {
    @include font-user-name;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 0;
    color: $color-header;
  }

  .divider {
    height: 1px;
    width: 65%;
    background-color: black;
  }
}
