@import '../../../scss/helpers';

.userInfoWrapper {
  display: flex;
  align-items: center;

  .userInfo {
    margin-left: 20px;

    a {
      &:hover {
        text-decoration: underline;
      }
    }

    @include mobile {
      display: flex;
      flex-direction: column;
    }

    .userInfoName {
      @include font-user-name;
      font-weight: 600;
      color: $menu-text-color;

      @include mobile {
        order: 2;
      }
    }

    .userInfoScore {
      margin-top: 6px;
      @include font-score-user;
      color: $cooperate-text-color;

      svg {
        fill: $star-cooperate-color;
      }

      .userInfoTotalCount {
        margin-left: 5px;
      }

      @include mobile {
        order: 1;
      }
    }
  }

  .userInfoAvatar {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.mobileView {
    @include mobile {
      display: flex;
      flex-direction: column;

      .userInfo {
        margin-left: 0;
        margin-top: 10px;

        .userInfoName {
          text-align: center;
        }
      }
    }
  }
}
