@import '../../../scss/helpers';

.footerWrapper {
  display: flex;
  background: #fff;
  flex-direction: column;
  justify-content: center;

  .footerContent {
    @include grid();
    padding: 22px 0;
  }
}
